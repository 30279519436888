import { useEffect, useState } from "react"

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    if (!window.matchMedia) return

    const media = window.matchMedia(query) || {}
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => {
      setMatches(media.matches)
    }

    if (media.addEventListener) {
      media.addEventListener("change", listener)
    }
    return () =>
      media.removeEventListener && media.removeEventListener("change", listener)
  }, [matches, query])

  return matches
}

// how to hook these into theme/breakpoints
export const useIsMobile = () => useMediaQuery("(max-width: 54em)")

export const useIsDesktop = () => useMediaQuery("(min-width: 54em)")
