import { LocalizedString } from "@commercetools/platform-sdk"
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import { LinkVariants } from "@sixty-six-north/ui-system"
import { Option } from "funfix-core"
import { PrismicProductsContext } from "prismic/PrismicProductsContext"
import React, { useContext, ReactNode } from "react"

import { ThemeUIStyleObject } from "theme-ui"
import { useAllCategories } from "../../category/CategoryHooks"
import { Link as AppLink } from "../../components/Link"
import { ProductUri } from "../../components/Links"
import {
  useLocaleAwareLinks,
  usePreferredLanguage
} from "../../i18n/StoreHooks"
import { usePrismicNavigationLink } from "../../layout/NavigationHooks"
import { DomainCategory } from "../../product/models/DomainCategory"
import { useAppDispatch } from "../../State"
import { usePrismicLinks } from "../PrismicLinkedDocumentContext"
import {
  PrismicCategoryLink,
  PrismicColumnItem,
  PrismicCommerceToolsId,
  PrismicText
} from "../PrismicModels"

export const PrismicDocumentLink: React.FC<{
  documentId: string | undefined
  id?: string
  onClick?: (ev?: unknown) => void
  sx?: ThemeUIStyleObject
  className?: string
  children?: ReactNode
}> = ({ documentId, children, ...props }) => {
  const navLinks = usePrismicNavigationLink()
  const contentLinks = usePrismicLinks()

  const navLink = navLinks.linkForDocumentId(documentId)
  const docLink = contentLinks.linkForDocumentId(documentId)

  if (navLink) {
    return (
      <PrismicLink href={navLink.as} {...props}>
        {children}
      </PrismicLink>
    )
  } else if (docLink) {
    return (
      <PrismicLink href={docLink.as} {...props}>
        {children}
      </PrismicLink>
    )
  } else {
    return (
      <AppLink href="#" {...props}>
        {children}
      </AppLink>
    )
  }
}

export const PrismicLink: React.FC<{
  children?: ReactNode
  href: string
}> = ({ href, children, ...props }) => {
  const fullyQualifiedDomain = "https://www.66north.com/"
  const linkBuilder = useLocaleAwareLinks()

  if (href.startsWith(fullyQualifiedDomain)) {
    const sanitisedHref = href.replace(fullyQualifiedDomain, "/")
    const link = linkBuilder.genericLink(sanitisedHref)
    return (
      <AppLink {...props} {...link}>
        {children}
      </AppLink>
    )
  }

  return (
    <AppLink href={href} passHref={true} {...props}>
      {children}
    </AppLink>
  )
}

export const useCategoryForSlugApi = () => {
  const language = usePreferredLanguage()
  const categories = useAllCategories()
  return {
    categoryForSlug: (localisedSlugs: LocalizedString) => {
      const category = categories.find(
        it => it.slug[language] === localisedSlugs[language]
      )
      return category
    }
  }
}

export const useCategoryForSlug = (
  localisedSlugs: LocalizedString
): DomainCategory | undefined => {
  const api = useCategoryForSlugApi()
  return api.categoryForSlug(localisedSlugs)
}

export const CategoryLink: React.FC<{
  prismicCategory: PrismicCategoryLink
  onClick?: () => void
  children?: ReactNode
  sx?: ThemeUIStyleObject
}> = ({ prismicCategory, children, ...props }) => {
  const builder = useLocaleAwareLinks()
  const category = useCategoryForSlug(prismicCategory.slug)

  if (category) {
    const categoryLink = builder.categoryLink(category)
    return (
      <AppLink {...categoryLink} {...props}>
        {children}
      </AppLink>
    )
  } else {
    return null
  }
}

export const ProductLink: React.FC<{
  prismicProduct: PrismicCommerceToolsId
  prismicCategory: PrismicCategoryLink
  onClick?: () => void
  children?: ReactNode
  sx?: ThemeUIStyleObject
}> = ({ prismicProduct, prismicCategory, children, ...props }) => {
  const builder = useLocaleAwareLinks()
  const language = usePreferredLanguage()
  const { products } = useContext(PrismicProductsContext)
  const product = products.find(p => p.key === prismicProduct.productCode)
  if (product) {
    const productLink = builder.productProjectionLink(
      prismicCategory.slug[language],
      undefined,
      new ProductUri(product.slug, product.key)
    )
    return (
      <AppLink {...productLink} {...props}>
        {children}
      </AppLink>
    )
  } else {
    return null
  }
}

export const PrismicTrayLink: React.FC<{
  item: PrismicColumnItem
  action: ActionCreatorWithoutPayload<string>
  sx?: ThemeUIStyleObject
}> = ({ item, action }) => {
  const linkBuilder = useLocaleAwareLinks()

  const dispatch = useAppDispatch()
  const label = () =>
    Option.of((item.label || [])[0])
      .map(it => it.text)
      .getOrElse("")

  if (item.prismic_link && item.prismic_link.id) {
    return (
      <PrismicDocumentLink
        documentId={item.prismic_link.id}
        sx={{ variant: `links.${LinkVariants.navBar}` }}
        onClick={() => dispatch(action())}
      >
        {label()}
      </PrismicDocumentLink>
    )
  } else if (item.commerce_tools_category) {
    return (
      <CategoryLink
        prismicCategory={item.commerce_tools_category}
        sx={{ variant: `links.${LinkVariants.navBar}` }}
        onClick={() => dispatch(action())}
      >
        {label()}
      </CategoryLink>
    )
  } else if (item.internal_url) {
    const link = linkBuilder.genericLink(item.internal_url)
    return (
      <AppLink
        {...link}
        sx={{ variant: `links.${LinkVariants.navBar}` }}
        onClick={() => dispatch(action())}
      >
        {label()}
      </AppLink>
    )
  } else {
    return null
  }
}

export const PrismicMenuLink: React.FC<{
  item: PrismicColumnItem
}> = ({ item }) => {
  const linkBuilder = useLocaleAwareLinks()

  const label = () =>
    Option.of((item.label || [])[0])
      .map(it => it.text)
      .getOrElse("")

  if (item.prismic_link && item.prismic_link.id) {
    return (
      <PrismicDocumentLink
        documentId={item.prismic_link.id}
        sx={{ variant: `links.${LinkVariants.navBar}` }}
      >
        {label()}
      </PrismicDocumentLink>
    )
  } else if (item.commerce_tools_category) {
    return (
      <CategoryLink
        prismicCategory={item.commerce_tools_category}
        sx={{ variant: `links.${LinkVariants.navBar}` }}
      >
        {label()}
      </CategoryLink>
    )
  } else if (item.internal_url) {
    const link = linkBuilder.genericLink(item.internal_url)
    return (
      <AppLink {...link} sx={{ variant: `links.${LinkVariants.navBar}` }}>
        {label()}
      </AppLink>
    )
  } else {
    return null
  }
}
